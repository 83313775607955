<template>
	<div class="pui-form">
		<magente-modals :modelName="modelName"></magente-modals>

		<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary" show-arrows>
			<v-tab :key="0" :href="'#maintab'">{{ $t('magente.maintab') }}</v-tab>
			<v-tab :key="1" :href="'#notificacion'">{{ $t('magente.notificacion') }}</v-tab>
			<v-tab :key="2" :href="'#documentos'" v-if="!isCreatingElement && !isModalDialog">{{ $t('pui9.documents.tab') }}</v-tab>
		</v-tabs>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" lazy :value="'maintab'">
					<v-layout wrap class="pui-form-layout">
						<v-flex xs12 md12>
							<pui-field-set :title="getTitleDatos">
								<v-layout wrap>
									<!-- IDAGENTE -->
									<v-flex xs12 md1>
										<pui-text-field
											:id="`idagente-magente`"
											v-model="model.idagente"
											:label="$t('magente.idagente')"
											disabled
											toplabel
											maxlength="10"
										></pui-text-field>
									</v-flex>
									<v-flex xs12 md2>
										<pui-select
											id="codtipodocumento"
											attach="codtipodocumento"
											:label="$t('magente.codtipodocumento')"
											toplabel
											clearable
											:disabled="formDisabled"
											v-model="model"
											modelName="mtipodocumento"
											:itemsToSelect="[{ id: model.codtipodocumento }]"
											:modelFormMapping="{ id: 'codtipodocumento' }"
											itemValue="id"
											itemText="name"
											:order="{ name: 'asc' }"
										></pui-select>
									</v-flex>
									<!-- NIF -->
									<v-flex xs12 md2>
										<pui-text-field
											:id="`nif-magente`"
											v-model="model.nif"
											:label="$t('magente.nif')"
											:disabled="formDisabled"
											required
											toplabel
											maxlength="35"
										></pui-text-field>
									</v-flex>

									<v-flex xs12 md2>
										<pui-select
											id="codidioma"
											attach="codidioma"
											:label="$t('magente.codidioma')"
											toplabel
											clearable
											:disabled="formDisabled"
											v-model="model"
											modelName="puilanguage"
											:itemsToSelect="[{ isocode: model.codidioma }]"
											:modelFormMapping="{ isocode: 'codidioma' }"
											itemValue="isocode"
											itemText="name"
											:order="{ name: 'asc' }"
										></pui-select>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<!-- NOMBRE -->
									<v-flex xs12 md6>
										<pui-text-field
											:id="`nombre-magente`"
											v-model="model.nombre"
											:label="$t('magente.nombre')"
											:disabled="formDisabled"
											toplabel
											maxlength="250"
										></pui-text-field>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<!-- EMAIL -->
									<v-flex xs12 md3>
										<pui-text-field
											:id="`email-magente`"
											v-model="model.email"
											:label="$t('magente.email')"
											:disabled="formDisabled"
											toplabel
											maxlength="250"
										></pui-text-field>
									</v-flex>
									<!-- NUMERO -->
									<v-flex xs12 md3>
										<pui-text-field
											:id="`numero-magente`"
											v-model="model.numero"
											:label="$t('magente.numero')"
											:disabled="formDisabled"
											toplabel
											maxlength="100"
										></pui-text-field>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<!-- FECINIVIGENCIA -->
									<v-flex xs12 md3>
										<pui-date-field
											:id="`fecinivigencia-magente`"
											v-model="model.fecinivigencia"
											:label="$t('magente.fecinivigencia')"
											:disabled="formDisabled"
											toplabel
											time
											required
										></pui-date-field>
									</v-flex>
									<!-- FECFINVIGENCIA -->
									<v-flex xs12 md3>
										<pui-date-field
											:id="`fecfinvigencia-magente`"
											v-model="model.fecfinvigencia"
											:label="$t('magente.fecfinvigencia')"
											:disabled="formDisabled"
											toplabel
											time
											:min="model.fecinivigencia"
										></pui-date-field>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12 md6>
										<pui-checkbox
											v-if="tipoEstibador"
											:id="`indautorizadommp-magente`"
											:label="$t('magente.indautorizadommp')"
											v-model="model.indautorizadommp"
											true-value="1"
											false-value="0"
										></pui-checkbox>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex md6>
										<pui-field-set :title="getTitlePuertos">
											<pui-multi-select
												itemValue="nombre"
												itemText="nombre"
												v-model="model.puertos"
												:items="allPorts"
												:itemsToSelect="model.puertos"
												:disabled="formDisabled"
											></pui-multi-select>
										</pui-field-set>
									</v-flex>
									<v-flex md6>
										<pui-field-set :title="getTitleTipos">
											<pui-multi-select
												itemValue="name"
												itemText="name"
												v-model="model.tipos"
												:items="allTipos"
												:itemsToSelect="model.tipos"
												:disabled="formDisabled"
											></pui-multi-select>
										</pui-field-set>
									</v-flex>
								</v-layout>
							</pui-field-set>
						</v-flex>
					</v-layout>
				</v-tab-item>
				<v-tab-item :key="1" :value="'notificacion'">
					<pui-field-set>
						<pui-quick-edition-datatable
							id="magentenotificacionquickedit-form"
							:parentModelName="modelName"
							:parentPk="this.pk"
							:parentPkChildFk="{ idagente: 'idagente' }"
							:disabled="formDisabled"
							modelName="notificacioncontacto"
							formComponentName="magentenotificacionquickedit-form"
							:parentModel="model"
							formPosition="top"
							showPaginator="false"
						></pui-quick-edition-datatable>
					</pui-field-set>
				</v-tab-item>
				<v-tab-item :key="2" lazy :value="'documentos'">
					<pui-master-detail componentName="puidocumentgrid" :parentModelName="modelName" :parentPk="pk"> </pui-master-detail>
				</v-tab-item>
			</v-tabs-items>
			<!-- MINI AUDIT -->
			<pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import magenteActions from './MagenteActions';
import magenteModals from './MagenteModals.vue';

export default {
	name: 'magente-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'magente-modals': magenteModals
	},
	data() {
		return {
			modelName: 'magente',
			actions: magenteActions.formActions,
			recompute: 0,
			recompute2: 0,
			multiselectWatchInterval: null,
			multiselectWatchInterval2: null,
			childModelName: 'notificacioncontacto'
		};
	},
	methods: {
		afterGetData() {},
		cleanAutorizacion(isEstibador) {
			if (!isEstibador) {
				this.model.indautorizadommp = 0;
			}
			console.log(this.model.indautorizadommp);
		}
	},
	computed: {
		getTitleDatos() {
			return this.$t('form.magente');
		},
		getTitlePuertos() {
			return this.$t('magente.puertos');
		},
		getTitleTipos() {
			return this.$t('magente.tipos');
		},
		allPorts() {
			this.recompute;
			return this.$store.state.magente && this.$store.state.magente.mpuertolocal ? this.$store.state.magente.mpuertolocal : [];
		},
		allTipos() {
			this.recompute2;
			return this.$store.state.magente && this.$store.state.magente.mtipoagente ? this.$store.state.magente.mtipoagente : [];
		},
		tipoEstibador() {
			let isEstibador = false;
			this.model.tipos.forEach((element) => {
				if (element.idtipoagente == 1) isEstibador = true;
			});
			this.cleanAutorizacion(isEstibador);
			return isEstibador;
		}
	},
	created() {
		this.$store.dispatch('puiMultiSelectGetAllItems', { model: 'magente', requestModel: 'mpuertolocal', vue: this });
		this.multiselectWatchInterval = setInterval(() => {
			this.recompute++;
			if (this.$store.state.magente && this.$store.state.magente.mpuertolocal) clearInterval(this.multiselectWatchInterval);
		}, 100);

		this.$store.dispatch('puiMultiSelectGetAllItems', { model: 'magente', requestModel: 'mtipoagente', vue: this });
		this.multiselectWatchInterval2 = setInterval(() => {
			this.recompute2++;
			if (this.$store.state.magente && this.$store.state.magente.mtipoagente) clearInterval(this.multiselectWatchInterval2);
		}, 100);
	},
	destroyed() {
		clearInterval(this.multiselectWatchInterval);
		clearInterval(this.multiselectWatchInterval2);
	}
};
</script>
